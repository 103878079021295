/* You can add global styles to this file, and also import other style files */
/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ets-framebox {
  border: 1px solid greenyellow;
}

// dx override
.dx-progressbar-range {
  position: relative;
  border: 1px solid #0c8b0c;
  background-color: #0c8b0c;
  margin-top: -1px;
  box-sizing: content-box;
}

span.ag-overlay-loading-center {
  background: #2d3436;
}

.ets-header-center {
  .ag-header-cell-label {
    float: none !important;
    width: auto !important;
    justify-content: center;
  }

  .ag-header-cell-menu-button {
    position: absolute;
    float: none;
  }
}

.ets-text-centered {
  text-align: center;
}

.ets-positive-number {
  color: green;
}

.ets-negative-number {
  color: red;
}

ets-trading-pad-buy-sell {
  span.dx-button-text {
    font-weight: 700;
  }
}

.ets-gateways {
  .dx-switch {
    width: 65px;
    height: 30px;
  }

  .dx-switch-container {
    height: 30px;

    .dx-switch-handle {
      height: 26px;
      flex-basis: 30px;
    }
  }

  .dx-switch-on,
  .dx-switch-off {
    line-height: 26px;
    padding-right: 30px;
  }

  .dx-field-label {
    margin-top: 5px;
    font-size: 120%;
  }

  .dx-switch.dx-state-focused .dx-switch-container {
    border-color: transparent;
  }

  /* OFF */
  .dx-switch-handle:before {
    background-color: red;
  }

  /* OFF.focused */
  .dx-state-focused .dx-switch-container .dx-switch-handle:before {
    background-color: red;
  }

  .dx-switch-on-value .dx-switch-handle:before {
    background-color: #17d917;
  }

  .dx-state-focused.dx-switch-on-value .dx-switch-handle:before {
    background-color: #17d917;
  }

  .dx-state-disabled .dx-switch-container .dx-switch-handle:before {
    background-color: #9c9595;
  }
}

.ets-disabled {
  pointer-events: none;
  opacity: 0.4;
  user-select: none;
}

.ets-hidden {
  display: none;
}

.ets-transparent {
  opacity: 0;
}

.ag-header-group-cell-label {
  justify-content: center;
}

.ag-theme-balham-dark .ag-ltr .ag-row-group-indent-1 {
  padding-left: 0px;
}

.ag-theme-balham-dark .ag-ltr .ag-row-group-indent-2 {
  padding-left: 5px;
}

.ag-theme-balham-dark .ag-ltr .ag-row-group-indent-3 {
  padding-left: 10px;
}

.toastr-top-wide-width {
  top: 0;
  right: 0;
  width: 100%;

  .ngx-toastr {
    width: 33%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.toastr-bottom-wide-width {
  bottom: 0;
  right: 0;
  width: 100%;

  .ngx-toastr {
    width: 33%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.as-split-area {
  background-color: #2d3436;
}

.dx-popup-wrapper > .dx-overlay-content {
  background-color: #2d3436;
}

.dx-popup-title.dx-toolbar {
  background-color: black;
}

.toast-center-left {
  left: 10px;
  top: 50%;
  right: 10px;
  transform: translate(0px, -50%);
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-center-right {
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
}

.ctxMenu {
  .dx-submenu {
    border-radius: 5px;
    border-width: medium;
  }
}

#ets-pause-dte-x {
  .dx-list-item-content {
    text-align: center;
  }

  ::ng-deep .dx-list-item-content {
    text-align: center;
  }
}

.opg-difference {
  background: yellow;
  color: black;
}

.ets-dx-center {
  ::ng-deep .dx-texteditor-input {
    text-align: center;
  }
}

.ets-remove-popover-arrow {
  .dx-popover-arrow {
    display: none;
  }
}

.pmd-column {
  background: #e8ded1;
  color: black;
  font-weight: bold;
}


.ag-theme-balham-dark .ag-ltr .ag-cell-range-single-cell, .ag-theme-balham-dark .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-balham-dark .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-balham-dark .ag-rtl .ag-cell-range-single-cell,
.ag-theme-balham-dark .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle, .ag-theme-balham-dark .ag-rtl
.ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border-right-color: rgb(33, 150, 243) !important;
}



ets-hedge-matrix-colgroup-header {
  width: 100%;
}